<!--
 * @Description: 
 * @Author: yesger
 * @Date: 2023-01-02 13:01:12
 * @LastEditTime: 2023-01-05 10:28:35
 * @LastEditors:  
-->
<template>
    <view class="index">
        首页
    </view>
</template>