/*
 * @Description: 
 * @Author: yesger
 * @Date: 2023-01-05 10:20:36
 * @LastEditTime: 2023-02-14 20:25:24
 * @LastEditors:  
 */
import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import 'view-ui-plus/dist/styles/viewuiplus.css'
const app = createApp(App)

app.use(store)
    .use(router)
    .use(ViewUIPlus, {
        transfer: true,
        size: 'large',
        capture: false,
        select: {
            arrow: 'md-arrow-dropdown',
            arrowSize: 20
        }
    })
    .mount('#app')