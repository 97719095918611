/*
 * @Description: 
 * @Author: yesger
 * @Date: 2023-01-01 22:46:42
 * @LastEditTime: 2023-01-02 12:59:51
 * @LastEditors:  
 */
import { createStore } from 'vuex'

export default createStore({
    // 状态
    state: {
        showside: true
    },
    getters: {},
    // 修改状态的方法
    mutations: {
        changeShowSide(state, is_show) {
            state.showside = is_show
        }
    },
    actions: {},
    modules: {}
})