/*
 * @Description: 
 * @Author: yesger
 * @Date: 2023-01-01 22:46:42
 * @LastEditTime: 2023-01-02 11:04:01
 * @LastEditors:  
 */
import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '@/views/IndexView.vue'
import ViewUIPlus from 'view-ui-plus'
const routes = [{
        path: '/',
        name: 'index',
        component: IndexView
    },
    // 粉丝管理
    {
        path: '/fans',
        component: () =>
            import ('@/views/FansListView.vue'), //懒加载
    },
    {
        path: '/message',
        component: () =>
            import ('@/views/MsgListView.vue'), //懒加载
    },
    // 活动管理
    {
        path: '/action',
        component: () =>
            import ('@/views/ActionListView.vue'), //懒加载
    },
    {
        path: '/addaction',
        component: () =>
            import ('@/views/AddNewAction.vue'), //懒加载
    },

]

const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    })
    // 全局拦截 (守卫)

router.beforeEach((to, from, next) => {
    ViewUIPlus.LoadingBar.start();
    next();
})
router.afterEach(route => {
    ViewUIPlus.LoadingBar.finish();
});

export default router