<!--
 * @Description: 
 * @Author: yesger
 * @Date: 2023-01-01 22:46:42
 * @LastEditTime: 2023-01-05 16:50:36
 * @LastEditors:  
-->
<template>
  <div class="box">
      <div class="header w">
          <div class="logo-box">
                  
              <a href="/index" class="logo">
                  <img src="./assets/logo.jpg" alt="" srcset="">
              </a>
          </div>
          <div class="menu-box">
              <Menu mode="horizontal" :theme="theme" active-name="1">
                  <MenuItem name="1">
                      <Icon type="ios-paper" />
                      内容管理
                  </MenuItem>
                  <MenuItem name="2">
                      <Icon type="ios-people" />
                      用户管理
                  </MenuItem>
                  <Submenu name="3">
                      <template #title>
                          <Icon type="ios-stats" />
                          统计分析
                      </template>
                      <MenuGroup title="使用">
                          <MenuItem name="3-1">新增和启动</MenuItem>
                          <MenuItem name="3-2">活跃分析</MenuItem>
                          <MenuItem name="3-3">时段分析</MenuItem>
                      </MenuGroup>
                      <MenuGroup title="留存">
                          <MenuItem name="3-4">用户留存</MenuItem>
                          <MenuItem name="3-5">流失用户</MenuItem>
                      </MenuGroup>
                  </Submenu>
                  <MenuItem name="4">
                      <Icon type="ios-construct" />
                      综合设置
                  </MenuItem>
              </Menu>
              <div class="login-box">
                  <Avatar icon="ios-person"  />
                  <Button type="text">
                      <a href="/login">登陆</a>
                  </Button>
              </div>
          </div>
      </div>
      <div class="main-box w">
          <div class="sidebar" v-if="$store.state.showside">
          <Menu :theme="theme" :open-names="[0]" @on-select="getThisItem" active-name="0-0">
              <Submenu :name="index" v-for="(item,index) in menulist" v-key="item">
                  <template #title>
                      <Icon :type="item.icon" />
                      {{ item.top_menu }}
                  </template>
                  <MenuItem :name="index+'-'+index2" :to="child.topage" v-for="(child,index2) in item.children" v-key="child">{{ child.name }}</MenuItem>
              </Submenu>
          </Menu>
          </div>
          <div class="main">
              <router-view></router-view>
          </div>
      </div>
      <div class="footer">
      </div>
  </div>
</template>
<style lang="less">
.w{
  padding: 0;
}
.box{
  width: 100vw;
  height: 100vh;
  // background: linear-gradient(to bottom, #f1f4f9, #dff1ff);
  .header{
      height: 80px;
      width: 100%;
      background-color: #fff;
      position: fixed;
      top: 0;
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      .logo-box{
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          .logo{
              width: 200px;
              height: 60px;
              background-color: aqua;
              img{
                  width: 100%;
                  height: 100%;
              }
          }
      }
      .menu-box{
          flex: 10;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .login-box{
              display: flex;
          }
      }
      
  }
  .main-box{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
      .sidebar{
          flex: 2;
          overflow-y: scroll;
      }
      .main{
          flex: 10;
          overflow-y: scroll;
          padding: 20px;
      }
  }
}
</style>

<script>
  export default {
      // 数据
      data(){
          return{
              is_show:true,
              menulist:[
                  {'top_menu':'数据看板','children':[{'name':'数据总览','topage':'/'},],'icon':'md-analytics'},
                  {'top_menu':'粉丝管理','children':[{'name':'粉丝列表','topage':'/fans'},{'name':'消息管理','topage':'/message'},],'icon':'ios-people'},
                  {'top_menu':'活动管理','children':[{'name':'活动列表','topage':'/action'},{'name':'新增活动','topage':'/addaction'},],'icon':'logo-chrome'},

              ]
          }
      },
      // 局部注册组件
      components: {
      }, 
      // 组件方法
      methods: {
          getThisItem(data){
              console.log('111',data)
          }
      },
      // 计算属性
      computed: {
      
      },
      watch: {
      
      },
      // 生命周期
      mounted () {
       
      }
  }
</script>